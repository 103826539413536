import gql from 'graphql-tag';

export default {
  /**
   * QUERY
   */

  getCasesRoot: gql`
    query getCasesRootQuery($knowledge: String!, $lang: String) {
      getCasesRoot(knowledge: $knowledge, lang: $lang) {
        id
        label
        body
        root
        knowledge
        knowledgeId
        hasChildren
        icon {
          iconType
          value
          color
        }
        miniIcon {
          iconType
          value
          color
        }
      }
    }
  `,
  getSettingsCasesRoot: gql`
    query getSettingsCasesRootQuery($knowledge: String!) {
      getCasesRoot(knowledge: $knowledge) {
        id
        label
        body
        root
        knowledge
        knowledgeId
        hasChildren
        childrenOrder {
          id
          entityType
        }
        icon {
          iconType
          value
          color
        }
        miniIcon {
          iconType
          value
          color
        }
      }
    }
  `,
  getCasesRootWithChildren: gql`
    query getCasesRootWithChildren($knowledge: String!, $lang: String) {
      casesRoot: getCasesRoot(knowledge: $knowledge, lang: $lang) {
        id
        label
        userPermissions
        untranslatedWithTranslatedContents
        icon {
          iconType
          value
          color
        }
        miniIcon {
          iconType
          value
          color
        }
        root
        body
        knowledge
        knowledgeId
        returnLanguage
        setLanguages
      }
    }
  `,
  getDetailedCaseById: gql`
    query getCaseById(
      $caseId: ID!
      $skipOrdered: Boolean!
      $skipPath: Boolean!
      $lang: String
      $publishedContentsOnly: Boolean
    ) {
      caseParameter: getCaseById(caseId: $caseId, lang: $lang) {
        id
        label
        labelTranslations
        body
        knowledge
        hasChildren
        userPermissions
        root
        untranslatedWithTranslatedContents
        children {
          id
          label
          body
          knowledge
          hasChildren
          childrenOrder {
            id
            entityType
          }
          returnLanguage
          setLanguages
        }
        orderedChildrenAndContents(
          publishedContentsOnly: $publishedContentsOnly
        ) @skip(if: $skipOrdered) {
          __typename
          ... on Case {
            id
            label
            userPermissions
            untranslatedWithTranslatedContents
            childrenOrder {
              id
              entityType
            }
            returnLanguage
            setLanguages
          }
          ... on Content {
            id
            label
            type
            published
            isOutdated
            caseParameters
            parameters
            userPermissions
            labels {
              id
              labelName
              icon
              color
            }
            returnLanguage
            setLanguages
          }
        }
        childrenOrder {
          id
          entityType
        }
        icon {
          iconType
          value
          color
        }
        miniIcon {
          iconType
          value
          color
        }
        path @skip(if: $skipPath) {
          id
          label
          children {
            id
            label
          }
        }
        returnLanguage
        setLanguages
      }
    }
  `,
  getLightCaseById: gql`
    query getCaseById($caseId: ID!, $lang: String) {
      case: getCaseById(caseId: $caseId, lang: $lang) {
        id
        label
        returnLanguage
      }
    }
  `,
  getCasesById: gql`
    query getCasesByIdQuery($ids: [ID!]) {
      getCasesById(ids: $ids) {
        id
        label
      }
    }
  `,
  getCasesTranslationState: gql`
    query getCasesByIdQuery($ids: [ID!], $lang: String) {
      getCasesById(ids: $ids, lang: $lang) {
        id
        untranslatedWithTranslatedContents
      }
    }
  `,
  getContentsByCaseId: gql`
    query getCaseById(
      $caseId: ID!
      $pageSize: Int
      $page: Int
      $search: String
    ) {
      relatedContents: getCaseById(caseId: $caseId) {
        id
        label
        strictResolutions(pageSize: $pageSize, page: $page, search: $search) {
          results {
            ... on Content {
              id
              type
              knowledgeId: knowledge
              label
              body
              type
              attachments
              tags
              attachedObjects
              published
              isOutdated
              icon
              path
              parameters
              caseParameters
              author
              accessRestrictions
              isGeneric
              labels {
                id
                labelName
                icon
                color
              }
              parents
              isReadOnly
              returnLanguage
              setLanguages
              userPermissions
            }
          }
          total
          pages
          page
        }
      }
    }
  `,
  getCaseResolutionsById: gql`
    query getCaseByIdQuery(
      $caseId: ID!
      $productId: ID!
      $count: Int
      $search: String
    ) {
      getCaseById(caseId: $caseId) {
        id
        label
        resolutions(productId: $productId, count: $count, search: $search) {
          results {
            ... on Content {
              id
              label
              type
              isOutdated
              published
              labels {
                id
                labelName
                icon
                color
              }
            }
          }
          total
        }
      }
    }
  `,
  getPartialProductCaseResolutionsById: gql`
    query getCaseByIdQuery(
      $caseId: ID!
      $parameters: [JSON!]
      $count: Int
      $search: String
    ) {
      getCaseById(caseId: $caseId) {
        id
        label
        resolutions: partialProductResolutions(
          parameters: $parameters
          count: $count
          search: $search
        ) {
          results {
            ... on Content {
              id
              label
              type
              isOutdated
              published
              labels {
                id
                labelName
                icon
                color
              }
            }
          }
          total
        }
      }
    }
  `,
  getCasesRootByProductId: gql`
    query getCasesRootByProductIdQuery($productId: ID!, $knowledge: String!) {
      getCasesRoot(knowledge: $knowledge) {
        id
        label
        icon {
          iconType
          value
          color
        }
        body
        root
        resolutionsCount(productId: $productId)
      }
    }
  `,
  getCasesRootByPartialProduct: gql`
    query getCasesRootByPartialProductQuery(
      $parameters: [JSON!]
      $knowledge: String!
    ) {
      getCasesRoot(knowledge: $knowledge) {
        id
        label
        icon {
          iconType
          value
          color
        }
        body
        root
        resolutionsCount: partialProductResolutionsCount(
          parameters: $parameters
        )
      }
    }
  `,
  getCaseChildren: gql`
    query getCaseChildrenQuery(
      $caseId: ID!
      $knowledge: String!
      $lang: String
    ) {
      getCaseChildren(caseId: $caseId, knowledge: $knowledge, lang: $lang) {
        id
        label
        body
        hasChildren
        setLanguages
      }
    }
  `,
  getSettingsCaseChildren: gql`
    query getSettingsCaseChildrenQuery(
      $caseId: ID!
      $knowledge: String!
      $lang: String
    ) {
      getCaseChildren(caseId: $caseId, knowledge: $knowledge, lang: $lang) {
        id
        label
        body
        hasChildren
        setLanguages
        childrenOrder {
          id
          entityType
        }
      }
    }
  `,
  getCaseChildrenByProductId: gql`
    query getCaseChildrenByProductIdQuery(
      $caseId: ID!
      $productId: ID!
      $knowledge: String!
    ) {
      getCaseChildren(caseId: $caseId, knowledge: $knowledge) {
        id
        label
        body
        hasChildren
        resolutionsCount(productId: $productId)
      }
    }
  `,
  getChildrenByPartialProduct: gql`
    query getChildrenByPartialProductQuery(
      $caseId: ID!
      $parameters: [JSON!]
      $knowledge: String!
    ) {
      getCaseChildren(caseId: $caseId, knowledge: $knowledge) {
        id
        label
        body
        hasChildren
        resolutionsCount: partialProductResolutionsCount(
          parameters: $parameters
        )
      }
    }
  `,
  getRebuiltCasePathFromCaseId: gql`
    query getCaseByIdQuery($caseId: ID!, $lang: String) {
      caseParameter: getCaseById(caseId: $caseId, lang: $lang) {
        id
        label
        path {
          id
          label
          root
          children {
            id
            label
          }
        }
      }
    }
  `,
  getOrderedChildrenAndContents: gql`
    query getOrderedChildrenAndContents(
      $caseId: ID!
      $knowledge: String!
      $childrenOrder: [JSON!]!
      $lang: String
    ) {
      children: getOrderedChildrenAndContents(
        caseId: $caseId
        knowledge: $knowledge
        childrenOrder: $childrenOrder
        lang: $lang
      ) {
        __typename
        ... on Case {
          id
          label
          userPermissions
          childrenOrder {
            id
            entityType
          }
          returnLanguage
          defaultLanguage
          setLanguages
          untranslatedWithTranslatedContents
          knowledge
          hasChildren
        }
        ... on Content {
          id
          label
          type
          published
          isOutdated
          caseParameters
          parameters
          userPermissions
          labels {
            id
            labelName
            icon
            color
          }
          children {
            id
            label
            body
            type
            path
          }
          returnLanguage
          defaultLanguage
          setLanguages
        }
      }
    }
  `,
  getCaseArchiveById: gql`
    query getCaseArchiveById(
      $archiveId: ID!
      $skipAncestors: Boolean!
      $lang: String
    ) {
      getCaseArchiveById(archiveId: $archiveId, lang: $lang) {
        id
        case {
          id
          label
          knowledge
          setLanguages
          __typename
        }
        formerCaseParents {
          id
          label
        }
        createdAt
        children {
          ... on ContentArchive {
            id
            content {
              id
              label
              type
              __typename
            }
          }
          ... on CaseArchive {
            id
            content: case {
              id
              label
              __typename
            }
          }
        }
        caseArchiveAncestors @skip(if: $skipAncestors) {
          id
          case {
            id
            label
            knowledge
            __typename
          }
          formerCaseParents {
            id
            label
          }
          createdAt
          children {
            ... on ContentArchive {
              id
              content {
                id
                label
                type
                __typename
              }
            }
            ... on CaseArchive {
              id
              content: case {
                id
                label
                __typename
              }
            }
          }
        }
      }
    }
  `,

  /**
   * MUTATIONS
   */

  addCase: gql`
    mutation addCaseMutation($parentId: ID!, $label: String!, $lang: String) {
      addCase(parentId: $parentId, label: $label, lang: $lang) {
        id
        label
        body
        returnLanguage
        setLanguages
        userPermissions
      }
    }
  `,
  createRootCase: gql`
    mutation createRootCase(
      $label: String!
      $knowledge: String!
      $knowledgeId: ID!
      $lang: String
    ) {
      rootCase: createRootCase(
        label: $label
        knowledge: $knowledge
        knowledgeId: $knowledgeId
        lang: $lang
      ) {
        id
        label
        root
        body
        knowledge
        knowledgeId
        userPermissions
        children {
          id
          label
          hasChildren
        }
        childrenOrder {
          id
          entityType
        }
        returnLanguage
        setLanguages
      }
    }
  `,
  updateCase: gql`
    mutation updateCaseMutation(
      $caseId: ID!
      $label: String!
      $body: String
      $childrenOrder: [JSON!]!
      $lang: String
      $skipOrdered: Boolean!
    ) {
      updateCase(
        caseId: $caseId
        label: $label
        body: $body
        childrenOrder: $childrenOrder
        lang: $lang
      ) {
        id
        label
        labelTranslations
        root
        body
        path {
          id
          label
        }
        knowledge
        userPermissions
        children {
          id
          label
          body
          hasChildren
        }
        orderedChildrenAndContents @skip(if: $skipOrdered) {
          __typename
          ... on Case {
            id
            label
            userPermissions
            childrenOrder {
              id
              entityType
            }
            returnLanguage
            setLanguages
          }
          ... on Content {
            id
            label
            type
            published
            isOutdated
            caseParameters
            parameters
            userPermissions
            labels {
              id
              labelName
              icon
              color
            }
            returnLanguage
            setLanguages
          }
        }
        childrenOrder {
          id
          entityType
        }
        returnLanguage
        setLanguages
      }
    }
  `,
  updateCaseIcon: gql`
    mutation updateCaseIconMutation(
      $caseId: ID!
      $iconType: String!
      $value: String!
      $color: String
      $field: String
    ) {
      updateCaseIcon(
        caseId: $caseId
        iconType: $iconType
        value: $value
        color: $color
        field: $field
      ) {
        id
        label
        icon {
          iconType
          value
          color
        }
        miniIcon {
          iconType
          value
          color
        }
        root
        body
        knowledge
        children {
          id
          label
          body
          hasChildren
        }
      }
    }
  `,

  restoreCaseArchive: gql`
    mutation restoreCaseArchiveMutation($archiveId: ID!, $parent: ID!) {
      restoreCaseArchive(archiveId: $archiveId, parent: $parent) {
        id
      }
    }
  `,

  moveCase: gql`
    mutation moveCaseMutation(
      $caseId: ID!
      $newFullPath: String!
      $moveToRoot: Boolean!
      $newIndex: Int!
    ) {
      moveCase(
        caseId: $caseId
        newFullPath: $newFullPath
        moveToRoot: $moveToRoot
        newIndex: $newIndex
      ) {
        id
        label
        root
      }
    }
  `,

  unsetCaseLanguage: gql`
    mutation unsetCaseLanguageMutation($caseId: ID!, $lang: String!) {
      unsetCaseLanguage(caseId: $caseId, lang: $lang) {
        label
        setLanguages
        returnLanguage
        defaultLanguage
        untranslatedWithTranslatedContents
      }
    }
  `,

  deleteCase: gql`
    mutation deleteCaseMutation($caseId: ID!, $softDeleted: Boolean) {
      deleteCase(caseId: $caseId, softDeleted: $softDeleted)
    }
  `,
  deleteCaseIcon: gql`
    mutation deleteCaseIconMutation($caseId: ID!, $field: String) {
      deleteCaseIcon(caseId: $caseId, field: $field)
    }
  `,
  deleteCaseArchive: gql`
    mutation deleteCaseArchiveMutation($archiveId: ID!) {
      deleteCaseArchive(archiveId: $archiveId)
    }
  `,

  bulkDeleteCaseArchive: gql`
    mutation bulkDeleteCaseArchiveMutation($caseArchiveIds: [ID!]) {
      bulkDeleteCaseArchive(caseArchiveIds: $caseArchiveIds)
    }
  `,
};
